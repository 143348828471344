<template>
  <v-container>
    <div v-if="pageID==null||pageID=='top'">
      <v-row justify="center">
        <v-col class="mb-4" cols="2">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://qiita-image-store.s3.amazonaws.com/0/224979/profile-images/1514873692"
            transition="scale-transition"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <a
          v-for="(s, i) in sns"
          :key="i"
          :href="s.href"
          class="subheading mx-3"
          target="_blank"
        >
        <v-img :src="s.icon" width="30px"></v-img>
        </a>
      </v-row>

      <v-row class="text-center">
        <v-col class="mb-4">
          <br/>
          <p>たこやきさんの個人Webサイト「たこやきさんのつぶやき」へようこそ。<br/>
            このサイトでは、以下のコンテンツを取り揃えております。</p>
        </v-col>
      </v-row>

      <h2>Menu</h2>
      <v-row class="text-left">
        <v-col
          class="mb-5"
          cols="12" md="4"
        >
          <a href="/?pageID=tagList">
          <v-card class="mx-auto pa-4">
            <v-card-title><h3>タグ一覧</h3></v-card-title>
            <p style="text-left">Twitterに呟くには長い技術記事や旅行記などを投稿しています</p>
          </v-card></a>
        </v-col>

        <v-col
          class="mb-5"
          cols="12" md="4"
          >
          <a href="/?pageID=tag&tag=作品一覧">
          <v-card class="mx-auto pa-4">
            <v-card-title><h3>作品一覧</h3></v-card-title>
            <p style="text-left">チームや個人により開発している作品やこれまでの受賞作品などを紹介</p>
          </v-card></a>
        </v-col>

        <v-col
          class="mb-5"
          cols="12" md="4"
          >
          <a href="/?pageID=tag&tag=論文">
          <v-card class="mx-auto pa-4">
            <v-card-title><h3>論文</h3></v-card-title>
            <p style="text-left">大学の卒業論文やら高校時代に応募した論文やらのアーカイブ</p>
          </v-card>
          </a>
        </v-col>

        <v-col
          class="mb-5"
          cols="12" md="4"
        >
          <a href="/?pageID=tag&tag=コンテスト受賞">
          <v-card class="mx-auto pa-4">
            <v-card-title><h3>コンテスト受賞</h3></v-card-title>
            <p style="text-left">コンテスト入賞作のアーカイブ</p>
          </v-card></a>
        </v-col>

        <v-col
          class="mb-5"
          cols="12" md="4"
          >
          <a href="/?pageID=presentation-sheet&type=md">
          <v-card class="mx-auto pa-4">
            <v-card-title><h3>登壇資料
            </h3></v-card-title>
            <p style="text-left">イベント等で登壇した際の資料</p>
          </v-card></a>
        </v-col>
      </v-row>

      <h2>Recent Posts</h2>
      <v-row>
        <v-col
          class="mb-5"
          >
          <div v-for="page in recentPosts" :key="page.id">
            <a :href="'/?pageID='+page.id+'&type='+page.type">
              <v-card class="mx-auto pa-4">
                <h3>{{ page.title }}</h3>
                <p style="text-align: right;">
                  作成日時：{{ page.created | formatDate }}
                  更新日時：{{ page.updated | formatDate }}</p>
              </v-card>
            </a>
            <br/>
          </div>
        </v-col>
      </v-row>

      <h2>Twitter</h2>
      <v-row class="text-center">
        <v-col
          class="mb-5"
          >
          <v-card class="mx-auto">
            <Timeline id="takoyaki3333333" sourceType="profile" :options="{ tweetLimit: '10' }"/>
          </v-card>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="pageID!=null&&pageID!='top'&&pageID!='tag'&&pageID!='tagList'">
      <v-row>
        <v-col
          class="mb-5"
          >
          <p style="text-align: right;">
            作成日時：{{ page.created | formatDate }}<br/>
            更新日時：{{ page.updated | formatDate }}</p>
          <v-card class="mx-auto pa-4">
            <!-- 動的に埋め込むHTMLコンテンツ -->
            <div v-html="pageHTML"></div>
          </v-card>
          <br/>
          <a v-for="tag in page.tags" :key="tag" :href="'/?pageID=tag&tag='+tag">#{{ tag }} </a>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="pageID=='tag'">
      <v-row>
        <v-col
          class="mb-5"
          >
          <h2>タグ：<a>#{{ tag }}</a></h2>
          <div v-for="id in pageList" :key="id">
            <a :href="'/?pageID='+pages[id].id+'&type='+pages[id].type">
              <v-card class="mx-auto pa-4">
                <h3>{{ pages[id].title }}</h3>
                <p style="text-align: right;">
                作成日時：{{ pages[id].created | formatDate }}
                更新日時：{{ pages[id].updated | formatDate }}</p>
              </v-card>
            </a>
            <br/>
          </div>
        </v-col>
      </v-row>
    </div>

    <div v-if="pageID=='tagList'">
      <v-row>
        <v-col
          class="mb-5"
          >
          <h2>タグ一覧</h2>
          <a v-for="tag in tags" :key="tag.data" :href="'/?pageID=tag&tag='+tag.data">#{{ tag.data }} </a>
        </v-col>
      </v-row>
    </div>

  </v-container>
</template>

<script>
  import { Timeline } from "vue-tweet-embed";
  import { marked } from "marked";
  import highlight from 'highlightjs';
  import 'highlight.js/styles/github.css';

  export default {
    name: 'HelloWorld',
    components: {
      Timeline
    },
    data: () => ({
      pageHTML:'',
      pageID:'',
      type:'',
      pages:{},
      page:{},
      tag:'',
      tags:[],
      pageList:[],
      recentPosts:[],
      sns: [
        {
          text: 'GitHub',
          icon: '/assets/github-mark.png',
          href: 'https://github.com/takoyaki-3',
        },
        {
          text: 'Twitter',
          icon: '/assets/Twitter social icons - square - blue.png',
          href: 'https://twitter.com/takoyaki3333333',
        },
        {
          text: 'Qiita',
          icon: '/assets/qiita-favicon.png',
          href: 'https://qiita.com/takoyaki3',
        },
        {
          text: 'Zenn',
          icon: '/assets/zenn-logo-only.svg',
          href: 'https://zenn.dev/takoyaki3',
        },
        {
          text: 'Mail',
          icon: '/assets/mail.png',
          href: 'mailto:mail@takoyaki3.com',
        },
      ],
    }),
    filters: {
      formatDate(value) {
        if (!value) return "";
        let date = new Date(value);
        return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
      }
    },
    methods:{
      async fetchText(url){
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      },
      isIn(arr,item){
        for(let i=0;i<arr.length;i++){
          if(arr[i]==item) return true;
        }
        return false;
      }
    },
    async mounted() {
      let url = new URL(window.location.href);
      this.pageID = url.searchParams.get('pageID');
      this.type = url.searchParams.get('type');
      this.tag = url.searchParams.get('tag');

      // ステップ1: IDリストを取得する
      const idListResponse = await this.fetchText('https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-key');
      const ids = JSON.parse(idListResponse);
      for(let i=0;i<ids.length;i++){
        const id = ids[i].data;
        const url = `https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-article-${id}`;
        const postContent = await this.fetchText(url);
        const tmp = JSON.parse(postContent)
        const postJSON = JSON.parse(tmp[0].data);
        this.$set(this.pages, postJSON.id, postJSON);
        this.recentPosts.push(postJSON)
      }

      // ステップ2: 最新の3つのIDを取得する
      this.recentPosts = this.recentPosts.slice(0, 3);

      url = `https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-article-${this.pageID}`;

      // // ページリストの取得
      // const listData = JSON.parse(await this.fetchText('https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-key'));

      // タグ一覧の取得
      const tags = JSON.parse(await this.fetchText('https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-tags'));
      this.tags = tags;

      // タグごとの記事一覧の取得
      if (this.tag) {
        const ids = JSON.parse(await this.fetchText(`https://key-value-array-store.api.takoyaki3.com/?key=takoyaki3-com-tag-${this.tag}`));
        this.pageList = ids.map((e)=>{return e.data});
      }

      if(this.type==null) return;

      this.page = this.pages[this.pageID];

      let data = await this.fetchText(url);
      data = JSON.parse(data)
      data = JSON.parse(data[0].data)
      if(this.type=='md'){
        const markedOptions = {
          highlight: (code, lang) => {
            return (
              '<code class="hljs">' + highlight.highlightAuto(code, [lang]).value + '</code>'
            );
          }
        };

        data = marked(data.file,markedOptions);
      } else if (this.page.type == 'html'){
        data = data.file;
      }
      
      this.pageHTML = data;
    }
  }
</script>
