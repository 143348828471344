<template>
  <v-app style="background:#eee">
    <v-app-bar
      app
      color="teal"
      dark
    >
      <div class="d-flex align-center">
        <a href="/">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://qiita-image-store.s3.amazonaws.com/0/224979/profile-images/1514873692"
          transition="scale-transition"
          width="40"
        />
        </a>
        <a href="/">

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
        <h2 class="font-color:"><font color="#eee">
          たこやきさんのつぶやき</font>
        </h2></a>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn
        href="https://github.com/takoyaki-3/butter"
        target="_blank"
        text
      >
        <span class="mr-2">Sign in</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
    </v-app-bar>

    <v-main>
      <HelloWorld/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    HelloWorld,
  },
  data: () => ({
    //
  }),
};
</script>
